import * as React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { showDialog } from '../../../utils/AlertDialog';
import { LinkActionStyled } from './employerList.styled';
import PaginateList from '../../../lists/PaginateList';
// import { Can, AbilityContext } from '../../../permissions/Can';
// import { USER_PERMISSIONS } from '../../../permissions/rolesPermission';
import * as EmployerService from '../service/EmployerService';

class ListEmployerContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshData: false
    };
  }

  componentDidUpdate(prevState) {
    if (prevState.refreshData !== this.state.refreshData && this.state.refreshData === true) {
      this.resetRefreshData();
    }
  }

  resetRefreshData = () => {
    this.setState({ refreshData: false });
  };

  getHeaderTable = (text) => {
    const { t } = this.props;
    return <span title={t(text)}>{t(text)}</span>;
  };

  getColumns = () => {
    const { t, roles } = this.props;
    return [
      {
        Header: this.getHeaderTable('forms.employer.table.heads.name'),
        accessor: 'name'
      },
      {
        Header: this.getHeaderTable('forms.employer.table.heads.surname'),
        accessor: 'surname'
      },
      {
        Header: this.getHeaderTable('forms.employer.table.heads.email'),
        accessor: 'email'
      },
      {
        Header: this.getHeaderTable('forms.employer.table.heads.role'),
        accessor: 'role',
        Cell: (row) => {
          const rol = roles.find((rol) => rol.value === row.original.role);
          if (rol) {
            return t(rol.label);
          }
          return '';
        }
      },
      {
        Header: this.getHeaderTable('generic.tables.actions'),
        className: 'align-self-center',
        accessor: 'uuid',
        Cell: (row) => this.renderActions(row)
      }
    ];
  };

  renderActions = (obj) => {
    const { employerPaths } = this.props;
    return (
      <React.Fragment>
        <div style={{ textAlign: 'center' }}>
          <LinkActionStyled
            to={`${employerPaths.UPDATE_EMPLOYER}/${obj.original.uuid}`}
            type='routerDom'
            variant='secondary'
          >
            <i className='fas fa-edit' />
          </LinkActionStyled>
          <LinkActionStyled
            variant='secondary'
            onClick={() => this.handlerRemoveEmployer(obj.original.uuid)}
          >
            <i className='far fa-trash-alt' />
          </LinkActionStyled>
        </div>
      </React.Fragment>
    );
  };

  handlerRemoveEmployer = (value) => {
    const { t, urlBase, actions } = this.props;

    const okAction = async () => {
      const result = await EmployerService.delEmployer(value, urlBase, actions);
      if (result && result.deleted === true) {
        this.setState({ refreshData: true });
      }
    };

    showDialog(
      t('forms.employer.dialog.remove.title'),
      t('forms.employer.dialog.remove.text'),
      'warning',
      true,
      t('forms.employer.dialog.remove.buttonOk'),
      false,
      okAction,
      t('forms.employer.dialog.remove.buttonNo')
    );
  };

  toList = async (filter) => {
    const { urlBase, actions } = this.props;
    const result = await EmployerService.listFilterEmployer(filter, urlBase, actions);
    return result;
  };

  render() {
    const { t, filters, className, employerPaths, pageSize, titleVariant, iconAddVariant } =
      this.props;
    return (
      <div className={className || ''}>
        <PaginateList
          title={t('forms.employer.titleList')}
          formlinkToAdd={employerPaths.CREATE_EMPLOYER}
          defaultPageSize={pageSize}
          columns={this.getColumns()}
          toList={this.toList}
          filters={filters}
          forceRefresh={this.state.refreshData}
          iconAdd='fas fa-plus'
          variantTitle={titleVariant || 'primary'}
          floatButton
          variantIcon={iconAddVariant || 'primary'}
          classNameTable='payper-table'
        ></PaginateList>
      </div>
    );
  }
}

ListEmployerContainer.propTypes = {
  t: PropTypes.any,
  filters: PropTypes.object,
  className: PropTypes.string,
  employerPaths: PropTypes.object,
  pageSize: PropTypes.number,
  roles: PropTypes.array,
  urlBase: PropTypes.string,
  actions: PropTypes.object,
  titleVariant: PropTypes.string,
  iconAddVariant: PropTypes.string
};

export default withTranslation()(ListEmployerContainer);
