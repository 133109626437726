import React from 'react';
import List from '../rolesPermissionList';
import Title from '../../../general/Title';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const RolesPermissionListPage = (props) => {
  const { t } = useTranslation();
  const { rolesPaths, pageSize, urlBase, actions, titleVariant, iconAddVariant } = props;

  return (
    <>
      <div className='row justify-content-center'>
        <div className='col-12 col-md-10 marg-btm-1rem marg-top-40px'>
          <Title type='h3' variant={titleVariant || 'primary'}>
            {t('forms.rolesPermission.title')}
          </Title>
        </div>
      </div>
      <div className='row justify-content-center'>
        <div className='col-12 col-md-10'>
          <List
            className='marg-top-1rem'
            actions={actions}
            urlBase={urlBase}
            pageSize={pageSize}
            rolesPaths={rolesPaths}
            titleVariant={titleVariant}
            iconAddVariant={iconAddVariant}
          />
        </div>
      </div>
    </>
  );
};

RolesPermissionListPage.propTypes = {
  rolesPaths: PropTypes.object,
  pageSize: PropTypes.number,
  urlBase: PropTypes.string,
  actions: PropTypes.object,
  titleVariant: PropTypes.string,
  iconAddVariant: PropTypes.string
};

export default RolesPermissionListPage;
