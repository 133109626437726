import * as React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { Transition } from 'react-transition-group';
import {
  ButtonStyled,
  ButtonsContainer,
  FilterContainer,
  Animation,
  LinkShowFilterStyled
} from './FormFilter.styled';
import Button from '../../general/Button';
import Title from '../../general/Title';

const { useState, useEffect } = React;

const EmployerFilterForm = (props) => {
  const {
    onReset,
    title,
    dataModel,
    validationDataModel,
    initialValues,
    textButtonClean,
    textButtonSumbit,
    classNamefilterContainer,
    classNameButtonsContainer,
    formName,
    iconDown,
    iconUp,
    variantTitle,
    animationArrowClassname,
    autoComplete,
    forceResetValues
  } = props;
  const [showFilter, setShowFilter] = useState(true);
  const [initialValuesState, setInitialValuesState] = useState(initialValues);

  useEffect(() => {
    if (initialValues) {
      setInitialValuesState(initialValues);
    }
  }, [initialValues]);

  const handlerShowFilter = () => {
    setShowFilter(!showFilter);
  };

  const setInitialValues = (initialValues, dataModel) => {
    const newValues = { ...dataModel };
    if (initialValues) {
      const items = Object.keys(newValues);
      items.forEach((item) => {
        if (initialValues[item]) {
          newValues[item] = initialValues[item];
        }
      });
      return newValues;
    }

    return dataModel;
  };

  return (
    <React.Fragment>
      <div className='row'>
        <div className='col-10'>
          <Title type='h5' variant={variantTitle}>
            {title}
            <LinkShowFilterStyled
              borderVariant='primary'
              variant='primary'
              onClick={() => handlerShowFilter()}
              className={animationArrowClassname}
            >
              {showFilter ? (
                <i className={iconUp || 'fas fa-angle-up'} />
              ) : (
                <i className={iconDown || 'fas fa-angle-down'} />
              )}
            </LinkShowFilterStyled>
          </Title>
        </div>
      </div>
      <Transition in={showFilter} timeout={200} unmountOnExit>
        {(state) => (
          <Animation state={state} className='row'>
            <div className='col-12'>
              <FilterContainer className={classNamefilterContainer || ''}>
                <Formik
                  initialValues={
                    initialValuesState ? setInitialValues(initialValuesState, dataModel) : dataModel
                  }
                  enableReinitialize
                  validationSchema={validationDataModel}
                  displayName={formName || 'defaultForm'}
                  onSubmit={(values) => {
                    props.onSubmit(values);
                  }}
                >
                  {(propsFormik) => (
                    <Form autoComplete={autoComplete ? 'on' : 'off'}>
                      {props.children}
                      <div className='row'>
                        <ButtonsContainer
                          className={`${
                            classNameButtonsContainer ? classNameButtonsContainer : ''
                          } col-12`}
                        >
                          <ButtonStyled
                            type='button'
                            variant='secondary'
                            onClick={() => {
                              onReset();
                              propsFormik.resetForm();
                              if (forceResetValues) {
                                forceResetValues();
                              }
                            }}
                          >
                            {textButtonClean || 'Clear'}
                          </ButtonStyled>
                          <Button type='submit' variant='primary'>
                            {textButtonSumbit || 'Submit'}
                          </Button>
                        </ButtonsContainer>
                      </div>
                    </Form>
                  )}
                </Formik>
              </FilterContainer>
            </div>
          </Animation>
        )}
      </Transition>
    </React.Fragment>
  );
};

EmployerFilterForm.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  onReset: PropTypes.func,
  title: PropTypes.string,
  dataModel: PropTypes.object,
  validationDataModel: PropTypes.any,
  textButtonClean: PropTypes.string,
  textButtonSumbit: PropTypes.string,
  formName: PropTypes.string.isRequired,
  classNamefilterContainer: PropTypes.string,
  classNameButtonsContainer: PropTypes.string,
  iconDown: PropTypes.string,
  iconUp: PropTypes.string,
  variantTitle: PropTypes.string,
  animationArrowClassname: PropTypes.string,
  autoComplete: PropTypes.bool,
  forceResetValues: PropTypes.func
};

export default EmployerFilterForm;
