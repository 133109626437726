export const urlFilter = `v1/adm/employer/filter`;
export const urlEmployer = `v1/adm/employer`;

const generateFilterQuery = ({ filters, page, pageSize }) => {
  let filter = '?';

  page ? (filter += 'page=' + page) : (filter += 'page=1');
  pageSize ? (filter += '&pageSize=' + pageSize) : (filter += '&pageSize=' + 10);

  if (filters) {
    if (filters.name) filter += '&name=' + filters.name;
    if (filters.lang) filter += '&lang=' + filters.lang;
    if (filters.category) filter += '&category=' + filters.category;
  }

  return filter;
};

/**
 * Delete Employer
 */
export const delEmployer = async (uuid, urlBase, actions) => {
  const param = [uuid];
  const result = await actions.apiDelete(`${urlBase}${urlEmployer}`, param, true)();
  if (result && result.data) {
    return result.data;
  }
  return null;
};

/**
 * List Employer
 */
export const listFilterEmployer = async (filter, urlBase, actions) => {
  const urlFilterEmployer = `${urlBase}${urlFilter}`;
  const result = await actions.apiGet(
    urlFilterEmployer + generateFilterQuery(filter),
    undefined,
    true
  )();
  if (result && result.data) {
    return result.data;
  }
  return null;
};

/**
 * Get Employer
 */
export const getEmployer = async (uuid, urlBase, actions) => {
  const param = [uuid];
  const urlGetEmployer = `${urlBase}${urlEmployer}`;
  const result = await actions.apiGet(urlGetEmployer, param, true)();
  if (result && result.data) {
    return result.data;
  }
  return null;
};

/**
 * Create Employer
 */
export const addEmployer = async (values, urlBase, actions) => {
  const objSend = {
    name: values.name,
    surname: values.surname,
    role: values.role,
    password: values.password,
    email: values.email
  };
  const urlCreateEmployer = `${urlBase}${urlEmployer}`;
  const result = await actions.apiPost(urlCreateEmployer, objSend, undefined, true)();
  if (result?.data) {
    return 'OK';
  }
  return null;
};

/**
 * Update Employer
 */
export const updateDataEmployer = async (values, urlBase, actions) => {
  const objSend = {
    name: values.name,
    surname: values.surname,
    uuid: values.uuid,
    role: values.role
  };
  if (values.password && values.password.length > 0) {
    objSend.password = values.password;
  }
  const urlUpdateEmployer = `${urlBase}${urlEmployer}`;
  const result = await actions.apiPut(urlUpdateEmployer, objSend, undefined, true)();
  if (result?.data) {
    return 'OK';
  }
  return null;
};
