import React, { Component } from "react";
import PropTypes from "prop-types";
import List from "./PaginateList.view";
import { withTranslation } from "react-i18next";

class ListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      forceRefresData: false,
      columns: [],
      pageSize: props.defaultPageSize ? props.defaultPageSize : 10,
      pagination: {
        pageCount: 0,
        page: 0,
      },
    };
  }

  componentDidMount() {
    this.refreshData(this.props.filters);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.filters !== this.props.filters) {
      this.refreshData(this.props.filters);
    }
    if (
      prevProps.forceRefresh !== this.props.forceRefresh &&
      this.props.forceRefresh === true
    ) {
      this.setRefresData(this.props.forceRefresh);
    }
    if (
      prevState.forceRefresData !== this.state.forceRefresData &&
      this.state.forceRefresData === true
    ) {
      this.refreshData(true);
    }
  }

  setRefresData(forceRefresh) {
    this.setState({ forceRefresData: forceRefresh });
  }

  refreshData(resetRefresh = false) {
    if (resetRefresh) {
      this.setRefresData(false);
    }
    this.fetchData(0);
  }

  async fetchData(pageIndex, pageSizeSeted) {
    // const { defaultPageSize } = this.props;
    const { pageSize } = this.state;
    let pageSizeFinal = 0;
    if (pageSizeSeted) {
      pageSizeFinal = pageSizeSeted;
    } else {
      pageSizeFinal = pageSize;
    }
    const filter = {
      page: pageIndex + 1,
      pageSize: pageSizeFinal || 10,
    };
    if (this.props.filters) {
      filter.filters = this.props.filters;
    }

    this.getData(filter);
  }

  async getData(filter) {
    const { toList } = this.props;
    if (toList) {
      const newPag = { ...this.state.pagination };

      const result = await toList(filter);
      newPag.pageCount = result?.totalPages || 0;
      newPag.page = result?.page - 1 || 0;
      this.setState({
        data: result?.docs || [],
        pagination: newPag,
      });
    }
  }

  handleUpdate(pageIndex) {
    this.fetchData(pageIndex);
  }

  handleChangePageSize(pageIndex, pageSize) {
    this.fetchData(0, pageSize);
    this.setState({ pageSize: pageSize });
  }

  render() {
    const {
      t,
      title,
      formlinkToAdd,
      columns,
      defaultPageSize,
      sortable,
      showPageSizeOptions,
      showPaginationTop,
      iconAdd,
      classNameLinkAdd,
      classNameTable,
      tbPreviousText,
      tbNextText,
      tbLoadingText,
      tbNoDataText,
      tbPageText,
      tbOfText,
      tbRowsText,
      variantTitle,
      pageSizeOptions,
      floatButton,
      variantIcon,
      hideHeader,
      paginationComponent,
    } = this.props;
    return (
      <List
        hideHeader={hideHeader}
        floatButton={floatButton}
        variantIcon={variantIcon}
        title={title}
        formlinkToAdd={formlinkToAdd}
        data={this.state.data || []}
        columns={columns}
        pagination={this.state.pagination}
        defaultPageSize={defaultPageSize}
        pageSizeOptions={pageSizeOptions}
        showPaginationTop={showPaginationTop}
        showPageSizeOptions={showPageSizeOptions}
        sortable={sortable}
        iconAdd={iconAdd}
        classNameLinkAdd={classNameLinkAdd}
        classNameTable={classNameTable}
        tbPreviousText={t(tbPreviousText || "generic.tables.previous")}
        tbNextText={t(tbNextText || "generic.tables.next")}
        tbLoadingText={t(tbLoadingText || "generic.tables.loading")}
        tbNoDataText={t(tbNoDataText || "generic.tables.no_data")}
        tbPageText={t(tbPageText || "generic.tables.page")}
        tbOfText={t(tbOfText || "generic.tables.of")}
        tbRowsText={t(tbRowsText || "generic.tables.rows")}
        variantTitle={variantTitle}
        handleUpdate={(pageIndex) => this.handleUpdate(pageIndex)}
        handleUpdatePageChange={(pageSize, pageIndex) =>
          this.handleChangePageSize(pageIndex, pageSize)
        }
        paginationComponent={paginationComponent}
      />
    );
  }
}

ListContainer.propTypes = {
  title: PropTypes.string,
  formlinkToAdd: PropTypes.string,
  defaultPageSize: PropTypes.number,
  columns: PropTypes.array.isRequired,
  toList: PropTypes.func.isRequired,
  filters: PropTypes.object,
  forceRefresh: PropTypes.bool,
  resetRefreshData: PropTypes.func,
  sortable: PropTypes.bool,
  showPageSizeOptions: PropTypes.bool,
  showPaginationTop: PropTypes.bool,
  iconAdd: PropTypes.string,
  classNameLinkAdd: PropTypes.string,
  classNameTable: PropTypes.string,
  tbPreviousText: PropTypes.string,
  tbNextText: PropTypes.string,
  tbLoadingText: PropTypes.string,
  tbNoDataText: PropTypes.string,
  tbPageText: PropTypes.string,
  tbOfText: PropTypes.string,
  tbRowsText: PropTypes.string,
  variantTitle: PropTypes.string,
  pageSizeOptions: PropTypes.array,
  floatButton: PropTypes.bool,
  variantIcon: PropTypes.string,
  hideHeader: PropTypes.bool,
  paginationComponent: PropTypes.any,
};

export default withTranslation()(ListContainer);
