import React, { useState } from 'react';
import List from '../employerList';
import Filter from '../employerFilter';
import Title from '../../../general/Title';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const EmployerListPage = (props) => {
  const { employerPaths, pageSize, roles, urlBase, actions, titleVariant, iconAddVariant } = props;
  const { t } = useTranslation();
  const [filters, setfilters] = useState(undefined);

  const handleSetFilter = async (filter) => {
    setfilters(filter);
  };

  return (
    <div>
      <div className='row justify-content-center'>
        <div className='col-12 col-md-10 marg-btm-1rem marg-top-40px'>
          <Title type='h3' variant={titleVariant || 'primary'}>
            {t('forms.employer.title')}
          </Title>
        </div>
      </div>
      <div className='row justify-content-center'>
        <div className='col-12 col-md-10'>
          <Filter setFilters={handleSetFilter} titleVariant={titleVariant} />
        </div>
      </div>
      <div className='row justify-content-center'>
        <div className='col-12 col-md-10'>
          <List
            className='marg-top-1rem'
            filters={filters}
            actions={actions}
            urlBase={urlBase}
            roles={roles}
            pageSize={pageSize}
            employerPaths={employerPaths}
            titleVariant={titleVariant}
            iconAddVariant={iconAddVariant}
          />
        </div>
      </div>
    </div>
  );
};

EmployerListPage.propTypes = {
  employerPaths: PropTypes.object,
  pageSize: PropTypes.number,
  roles: PropTypes.array,
  urlBase: PropTypes.string,
  actions: PropTypes.object,
  titleVariant: PropTypes.string,
  iconAddVariant: PropTypes.string
};

export default EmployerListPage;
