export const urlRoles = `v1/adm/rolesPermission`;

/**
 * Get rol
 */
export const getRolePermissison = async (uuid, urlBase, actions) => {
  const param = [uuid];
  const urlRolesPermisionSecured = `${urlBase}${urlRoles}`;
  const result = await actions.apiGet(urlRolesPermisionSecured, param, true)();
  if (result && result.data) {
    return result;
  }
  return null;
};

/**
 * Update Rol permission
 */
export const updateRolesPermission = async (permissions, uuid, urlBase, actions) => {
  const objSend = { permissions };
  const param = [uuid];
  const urlRolesPermisionSecured = `${urlBase}${urlRoles}`;
  const result = await actions.apiPut(urlRolesPermisionSecured, objSend, param, true)();
  if (result?.data?.uuid) {
    return 'OK';
  }
  return null;
};

/**
 * List Roles permissions
 */
export const listFilter = async (filter, urlBase, actions) => {
  const urlRolesPermisionSecured = `${urlBase}${urlRoles}`;
  const result = await actions.apiGet(urlRolesPermisionSecured, undefined, true)();
  if (result && result.data) {
    return result.data;
  }
  return null;
};
