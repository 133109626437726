import styled from 'styled-components';
import Link from '../../../general/Link';

export const LinkActionStyled = styled(Link)`
  margin-right: 5px;
  border: 1px solid ${({ theme }) => (theme?.iconLink?.border ? theme.iconLink.border : '#000')};
  background: ${({ theme }) => (theme?.iconLink?.background ? theme.iconLink.background : '#000')};
  color: ${({ theme }) => (theme?.iconLink?.color ? theme.iconLink.color : '#000')};
  padding: 3px;
  border-radius: 5px;

  &:hover {
    border: 1px solid
      ${({ theme }) => (theme?.iconLink?.borderHover ? theme.iconLink.borderHover : '#000')};
    background: ${({ theme }) =>
      theme?.iconLink?.backgroundHover ? theme.iconLink.backgroundHover : '#000'};
    color: ${({ theme }) => (theme?.iconLink?.colorHover ? theme.iconLink.colorHover : '#000')};
  }
`;

export const LinkAction2Styled = styled.a`
  margin-right: 5px;
  color: #fff;
  font-size: 0.875rem;

  &:hover {
    color: #fff;
    font-size: 0.875rem;
  }
`;
