import * as React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import InputTextFormik from '../../../inputs/InputText/formik';
import FormFilter from '../../../utils/FormFilter';
import { dataModel, validationDataModel } from './employerFilter.data.form';

const EmployerFilterForm = (props) => {
  const { t } = useTranslation();
  const { setFilters, titleVariant } = props;

  return (
    <>
      <FormFilter
        dataModel={dataModel}
        validationDataModel={validationDataModel}
        title={t('forms.employer.titleFilter')}
        setFilters={setFilters}
        textButtonSumbit={t('forms.employer.buttons.filter')}
        textButtonClean={t('forms.employer.buttons.clean')}
        formName='EmployerFilterForm'
        classNamefilterContainer='dark-filter-container'
        variantTitle={titleVariant || 'primary'}
        animationArrowClassname='filter-arrow'
      >
        <div className='row'>
          <div className='col-12'>
            <Field
              id='name'
              className='form-control'
              labelField={t('forms.employer.labels.name')}
              name='name'
              component={InputTextFormik}
              fieldType='text'
              placeholder=''
            />
          </div>
        </div>
      </FormFilter>
    </>
  );
};

EmployerFilterForm.propTypes = {
  setFilters: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  titleVariant: PropTypes.string
};

export default EmployerFilterForm;
