import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';
import { withTranslation } from 'react-i18next';
import InputTextFormik from '../../../inputs/InputText/formik';
import InputSelectFormik from '../../../inputs/InputSelect/formik';
import { dataModel, validationDataModel, setInitialValues } from './employerForm.data.form';
import { ButtonsContainer } from './employerForm.styled';
import Button from '../../../general/Button';
import Title from '../../../general/Title';

const EmployerForm = (props) => {
  const { t, handleSubmit, onback, edit, roles, titleVariant } = props;

  return (
    <>
      <div className='row justify-content-center'>
        <div className='col-12 col-md-10'>
          <div className='row marg-btm-1rem marg-top-40px'>
            <div className='col-12'>
              <Title type='h3' variant={titleVariant || 'primary'}>
                {edit ? t('forms.employer.titleEdit') : t('forms.employer.titleNew')}
              </Title>
            </div>
          </div>
          <form className='form-horizontal bordered-row' onSubmit={handleSubmit}>
            <div className='row'>
              <div className='col-12'>
                <Field
                  id='name'
                  className='form-control'
                  labelField={t('forms.employer.labels.name')}
                  name='name'
                  component={InputTextFormik}
                  fieldType='text'
                  placeholder=''
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <Field
                  id='surname'
                  className='form-control'
                  labelField={t('forms.employer.labels.surname')}
                  name='surname'
                  component={InputTextFormik}
                  fieldType='text'
                  placeholder=''
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <Field
                  id='email'
                  className='form-control'
                  labelField={t('forms.employer.labels.email')}
                  name='email'
                  component={InputTextFormik}
                  fieldType='email'
                  placeholder=''
                  readOnly={edit}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <Field
                  id='role'
                  labelField={t('forms.employer.labels.role')}
                  name='role'
                  component={InputSelectFormik}
                  placeholder=''
                  optionLabel='label'
                  optionValue='value'
                  options={roles.map((item) => ({
                    ...item,
                    label: t(item.label)
                  }))}
                  classNamePrefix='dark'
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <Field
                  id='password'
                  className='form-control'
                  labelField={t('forms.employer.labels.password')}
                  name='password'
                  component={InputTextFormik}
                  fieldType='password'
                  placeholder=''
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <Field
                  id='passwordConfirm'
                  className='form-control'
                  labelField={t('forms.employer.labels.passwordConfirm')}
                  name='passwordConfirm'
                  component={InputTextFormik}
                  fieldType='password'
                  placeholder=''
                />
              </div>
            </div>
            <div className='row justify-content-center'>
              <ButtonsContainer className='col-10 col-sm-10 text-center containerButtons'>
                <Button
                  type='button'
                  className='btn btn-md'
                  variant='secondary'
                  onClick={() => onback()}
                >
                  {t('forms.employer.buttons.back')}
                </Button>
                <Button type='submit' className='btn btn-md' variant='primary'>
                  {t('forms.employer.buttons.save')}
                </Button>
              </ButtonsContainer>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

EmployerForm.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  onback: PropTypes.func,
  edit: PropTypes.bool,
  roles: PropTypes.array,
  titleVariant: PropTypes.string
};

const EmployerFormF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: (props) => {
    return validationDataModel(props.edit);
  },
  handleSubmit: (values, { props, resetForm }) => {
    props.onSubmit(values, resetForm);
  },
  enableReinitialize: true,
  displayName: 'EmployerForm'
})(withTranslation()(EmployerForm));

export default EmployerFormF;
