import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Filter from './FormFilter.view'

class FilterEmployerContainer extends Component {
  componentDidMount() {
    const { dataModel } = this.props
    let executeSetFilter = false
    if (dataModel) {
      const entries = Object.entries(dataModel)
      for (const element of entries) {
        if (element[1]) {
          executeSetFilter = true
        }
      }
      if (executeSetFilter) {
        this.handlerOnSubmitFilter(dataModel)
      }
    }
  }

  async handlerOnSubmitFilter(values) {
    let newFilter = {}

    const valuesFilters = Object.entries(values)
    valuesFilters.forEach((value) => {
      if (value[1] && value[1].toString().length > 0) {
        newFilter[value[0]] = value[1]
      }
    })

    this.props.setFilters(newFilter)
  }

  handlerOnCleanFilter() {
    const { forceResetValues } = this.props
    if (!forceResetValues) {
      this.props.setFilters(undefined)
    }
  }

  render() {
    const {
      dataModel,
      validationDataModel,
      initialValues,
      title,
      textButtonClean,
      textButtonSumbit,
      classNamefilterContainer,
      classNameButtonsContainer,
      formName,
      iconDown,
      iconUp,
      variantTitle,
      animationArrowClassname,
      forceResetValues
    } = this.props

    return (
      <Filter
        dataModel={dataModel}
        forceResetValues={forceResetValues}
        validationDataModel={validationDataModel}
        initialValues={initialValues}
        title={title}
        textButtonClean={textButtonClean}
        textButtonSumbit={textButtonSumbit}
        onSubmit={(values) => {
          this.handlerOnSubmitFilter(values)
        }}
        onReset={() => {
          this.handlerOnCleanFilter()
        }}
        formName={formName}
        iconDown={iconDown}
        iconUp={iconUp}
        classNamefilterContainer={classNamefilterContainer}
        classNameButtonsContainer={classNameButtonsContainer}
        variantTitle={variantTitle}
        animationArrowClassname={animationArrowClassname}
      >
        {this.props.children}
      </Filter>
    )
  }
}

FilterEmployerContainer.propTypes = {
  setFilters: PropTypes.func.isRequired,
  dataModel: PropTypes.object.isRequired,
  validationDataModel: PropTypes.any.isRequired,
  initialValues: PropTypes.object,
  title: PropTypes.string,
  textButtonClean: PropTypes.string,
  textButtonSumbit: PropTypes.string,
  formName: PropTypes.string.isRequired,
  classNamefilterContainer: PropTypes.string,
  classNameButtonsContainer: PropTypes.string,
  iconDown: PropTypes.string,
  iconUp: PropTypes.string,
  variantTitle: PropTypes.string,
  animationArrowClassname: PropTypes.string,
  forceResetValues: PropTypes.func
}

export default FilterEmployerContainer
