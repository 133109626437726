import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DualListBox from 'react-dual-listbox';
import { ButtonsContainer } from './rolesPermissionForm.styled';
import Button from '../../../general/Button';
import Title from '../../../general/Title';
import 'react-dual-listbox/lib/react-dual-listbox.css';

const RolePermissionForm = (props) => {
  const { t } = useTranslation();
  const { onSubmit, onback, permissions, hasPermissions, role, titleVariant } = props;
  const [selected, setSelected] = useState(hasPermissions);

  useEffect(() => {
    setSelected(hasPermissions);
  }, [hasPermissions]);

  const onChange = (selected) => {
    setSelected(selected);
  };

  return (
    <>
      <div className='row justify-content-center'>
        <div className='col-12 col-md-10'>
          <div className='row marg-btm-1rem marg-top-40px'>
            <div className='col-12'>
              <Title type='h3' variant={titleVariant || 'primary'}>
                {`${t('forms.rolesPermission.titleEdit')}: ${role?.role.toUpperCase()}`}
              </Title>
            </div>
          </div>
          <div className='row marg-btm-1rem marg-top-40px'>
            <div className='col-12'>
              <DualListBox
                canFilter
                options={permissions}
                selected={selected}
                onChange={onChange}
              />
            </div>
          </div>
          <div className='row justify-content-center'>
            <ButtonsContainer className='col-10 col-sm-10 text-center containerButtons'>
              <Button
                type='button'
                className='btn btn-md'
                variant='secondary'
                onClick={() => onback()}
              >
                {t('forms.rolesPermission.buttons.back')}
              </Button>
              <Button
                type='button'
                className='btn btn-md'
                variant='primary'
                onClick={() => onSubmit(role, selected)}
              >
                {t('forms.rolesPermission.buttons.save')}
              </Button>
            </ButtonsContainer>
          </div>
        </div>
      </div>
    </>
  );
};

RolePermissionForm.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  onback: PropTypes.func,
  edit: PropTypes.bool,
  permissions: PropTypes.array,
  hasPermissions: PropTypes.array,
  role: PropTypes.object,
  titleVariant: PropTypes.string
};

export default RolePermissionForm;
