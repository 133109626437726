import * as React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import Title from '../../general/Title';
import { AddItemContainerStyled, LinkAddItemStyled, FloatAddItem } from './PaginateList.styled';

class EmployersList extends React.Component {
  render() {
    const {
      data,
      columns,
      pagination,
      title,
      formlinkToAdd,
      defaultPageSize,
      sortable,
      showPageSizeOptions,
      showPaginationTop,
      iconAdd,
      classNameLinkAdd,
      classNameTable,
      tbPreviousText,
      tbNextText,
      tbLoadingText,
      tbNoDataText,
      tbPageText,
      tbOfText,
      tbRowsText,
      variantTitle,
      handleUpdate,
      pageSizeOptions,
      handleUpdatePageChange,
      floatButton,
      variantIcon,
      hideHeader,
      paginationComponent
    } = this.props;
    return (
      <React.Fragment>
        <div className='row'>
          <div className='col-10'>
            {title ? (
              <Title type='h5' variant={variantTitle}>
                {title}
              </Title>
            ) : (
              ''
            )}
          </div>
          {formlinkToAdd ? (
            <React.Fragment>
              {floatButton ? (
                <FloatAddItem
                  to={formlinkToAdd}
                  type='routerDom'
                  variant={variantIcon || 'primary'}
                  className={classNameLinkAdd || ''}
                >
                  <i className={iconAdd || 'fas fa-plus-square'} />
                </FloatAddItem>
              ) : (
                <AddItemContainerStyled className='col-2'>
                  <LinkAddItemStyled
                    to={formlinkToAdd}
                    type='routerDom'
                    variant={variantIcon || 'primary'}
                    className={classNameLinkAdd || ''}
                  >
                    <i className={iconAdd || 'fas fa-plus-square'} />
                  </LinkAddItemStyled>
                </AddItemContainerStyled>
              )}
            </React.Fragment>
          ) : (
            ''
          )}
        </div>
        <div className='row'>
          <div className='col-12'>
            {hideHeader ? (
              <ReactTable
                TheadComponent={() => null}
                data={data ? data : []}
                className={
                  classNameTable ? `${classNameTable} -striped -highlight` : '-striped -highlight'
                }
                PaginationComponent={paginationComponent ? paginationComponent : undefined}
                manual
                showPaginationTop={showPaginationTop || false}
                showPageSizeOptions={showPageSizeOptions || false}
                sortable={sortable || false}
                pageSizeOptions={pageSizeOptions ? pageSizeOptions : [10]}
                defaultPageSize={defaultPageSize ? defaultPageSize : 10}
                pages={pagination ? pagination.pageCount : 0}
                page={pagination ? pagination.page : 0}
                columns={columns}
                onPageChange={handleUpdate}
                onPageSizeChange={handleUpdatePageChange}
                previousText={tbPreviousText}
                nextText={tbNextText}
                loadingText={tbLoadingText}
                noDataText={tbNoDataText}
                pageText={tbPageText}
                ofText={tbOfText}
                rowsText={tbRowsText}
              />
            ) : (
              <ReactTable
                data={data ? data : []}
                className={
                  classNameTable ? `${classNameTable} -striped -highlight` : '-striped -highlight'
                }
                PaginationComponent={paginationComponent ? paginationComponent : undefined}
                manual
                showPaginationTop={showPaginationTop || false}
                showPageSizeOptions={showPageSizeOptions || false}
                sortable={sortable || false}
                pageSizeOptions={pageSizeOptions ? pageSizeOptions : [10]}
                defaultPageSize={defaultPageSize ? defaultPageSize : 10}
                pages={pagination ? pagination.pageCount : 0}
                page={pagination ? pagination.page : 0}
                columns={columns}
                onPageChange={handleUpdate}
                onPageSizeChange={handleUpdatePageChange}
                previousText={tbPreviousText}
                nextText={tbNextText}
                loadingText={tbLoadingText}
                noDataText={tbNoDataText}
                pageText={tbPageText}
                ofText={tbOfText}
                rowsText={tbRowsText}
              />
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

EmployersList.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  title: PropTypes.string,
  formlinkToAdd: PropTypes.string,
  defaultPageSize: PropTypes.number,
  sortable: PropTypes.bool,
  showPageSizeOptions: PropTypes.bool,
  showPaginationTop: PropTypes.bool,
  iconAdd: PropTypes.string,
  classNameLinkAdd: PropTypes.string,
  classNameTable: PropTypes.string,
  tbPreviousText: PropTypes.string,
  tbNextText: PropTypes.string,
  tbLoadingText: PropTypes.string,
  tbNoDataText: PropTypes.string,
  tbPageText: PropTypes.string,
  tbOfText: PropTypes.string,
  tbRowsText: PropTypes.string,
  variantTitle: PropTypes.string,
  handleUpdate: PropTypes.func,
  pageSizeOptions: PropTypes.array,
  handleUpdatePageChange: PropTypes.func,
  floatButton: PropTypes.bool,
  variantIcon: PropTypes.string,
  hideHeader: PropTypes.bool,
  paginationComponent: PropTypes.any
};

export default EmployersList;
