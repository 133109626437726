import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import * as RolesPermissionService from '../service/RolesPermissionService';
import { withTranslation } from 'react-i18next';
import RolePermissionForm from './rolesPermissionForm.view';
import { showDialog } from '../../../utils/AlertDialog';

class BotProviderContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role: null,
      permissions: [],
      hasPermissions: []
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  loadData = async () => {
    const { urlBase, actions } = this.props;
    if (this.props.uuid) {
      const result = await RolesPermissionService.getRolePermissison(
        this.props.uuid,
        urlBase,
        actions
      );
      if (result?.data) {
        this.setState({
          role: result.data,
          permissions: result.permissions.map((group) => {
            const options = group.permissions.map((per) => ({ value: per, label: per }));
            return { label: group.group, options: options };
          }),
          hasPermissions: result.data.permissions
        });
      }
    }
  };

  handleSubmit = async (role, permissions) => {
    const { urlBase, actions } = this.props;
    try {
      if (role.uuid) {
        const result = await RolesPermissionService.updateRolesPermission(
          permissions,
          role.uuid,
          urlBase,
          actions
        );
        let title = '';
        let text = '';
        let icon = '';
        if (result && result === 'OK') {
          if (this.state.edit) {
            title = this.props.t('forms.rolesPermission.messages.update.title');
            text = this.props.t('forms.rolesPermission.messages.update.success');
            icon = 'success';
          } else {
            title = this.props.t('forms.rolesPermission.messages.create.title');
            text = this.props.t('forms.rolesPermission.messages.create.success');
            icon = 'success';
          }
        } else {
          title = 'Error';
          text = this.props.t('forms.rolesPermission.messages.update.error');
          icon = 'error';
        }
        showDialog(title, text, icon, false, 'OK', false, undefined);
      }
    } catch (error) {
      //console.log('Error', error);
    }
  };

  handleOnback = () => {
    const { rolesPaths } = this.props;
    this.props.history.push(rolesPaths.PERMISSIONS);
  };

  render() {
    const { titleVariant } = this.props;
    return (
      <RolePermissionForm
        hasPermissions={this.state.hasPermissions}
        permissions={this.state.permissions}
        role={this.state.role}
        onSubmit={this.handleSubmit}
        onback={this.handleOnback}
        titleVariant={titleVariant}
      />
    );
  }
}

BotProviderContainer.propTypes = {
  t: PropTypes.any,
  rolesPaths: PropTypes.object,
  urlBase: PropTypes.string,
  actions: PropTypes.object,
  titleVariant: PropTypes.string
};

export default withRouter(withTranslation()(BotProviderContainer));
