import * as Yup from 'yup';
// import { ROLE_TRADER } from '../../../../constants/index';

export const dataModel = {
  name: '',
  surname: '',
  email: '',
  password: '',
  passwordConfirm: '',
  role: ''
  // botProviderUuid: '',
};

export const validationDataModel = (edit) => {
  if (edit) {
    return Yup.object().shape({
      name: Yup.string().required('forms.validations.required'),
      surname: Yup.string().required('forms.validations.required'),
      role: Yup.string().required('forms.validations.required'),
      password: Yup.string().optional('forms.validations.required'),
      passwordConfirm: Yup.string().when('password', {
        is: (value) => value && value.length > 0,
        then: Yup.string()
          .required('forms.validations.required')
          .oneOf([Yup.ref('password')], 'forms.validations.matchPassword'),
        otherwise: Yup.string().optional('')
      })
      // botProviderUuid: Yup.string().when('role', {
      //   is: (value) => value && value.length > 0 && value === ROLE_TRADER,
      //   then: Yup.string().required('forms.validations.required'),
      //   otherwise: Yup.string().optional(''),
      // }),
    });
  }

  return Yup.object().shape({
    name: Yup.string().required('forms.validations.required'),
    surname: Yup.string().required('forms.validations.required'),
    role: Yup.string().required('forms.validations.required'),
    email: Yup.string().required('forms.validations.required').email('forms.validations.email'),
    password: Yup.string().required('forms.validations.required'),
    passwordConfirm: Yup.string().when('password', {
      is: (value) => value && value.length > 0,
      then: Yup.string()
        .required('forms.validations.required')
        .oneOf([Yup.ref('password')], 'forms.validations.matchPassword'),
      otherwise: Yup.string().optional('')
    })
    // botProviderUuid: Yup.string().when('role', {
    //   is: (value) => value && value.length > 0 && value === ROLE_TRADER,
    //   then: Yup.string().required('forms.validations.required'),
    //   otherwise: Yup.string().optional(''),
    // }),
  });
};

export const setInitialValues = (initialValues) => {
  const newValues = { ...dataModel };
  if (initialValues) {
    const items = Object.keys(newValues);
    items.forEach((item) => {
      if (initialValues[item]) {
        newValues[item] = initialValues[item];
      }
    });
    return newValues;
  }

  return dataModel;
};
