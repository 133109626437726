import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import * as EmployerService from '../service/EmployerService';
import EmployerForm from './employerForm.view';
import { withTranslation } from 'react-i18next';
import { showDialog } from '../../../utils/AlertDialog';

class EmployerContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      values: null
      // providers: [],
      // isTrader: false
    };
  }

  componentDidMount() {
    const { urlBase, actions } = this.props;

    if (this.props.uuid) {
      EmployerService.getEmployer(this.props.uuid, urlBase, actions).then((result) => {
        if (result?.uuid) {
          this.setState({ values: result, edit: true });
        }
      });
    }
    // this.loadData();
  }

  // loadData = async () => {
  //   // const resultProvider = await BotProviderService.listAll();
  //   // if (resultProvider?.docs && resultProvider.docs.length > 0) {
  //   //   this.setState({ providers: resultProvider.docs });
  //   // }
  // };

  handleSubmit = async (values) => {
    const { employerPaths, urlBase, actions } = this.props;
    try {
      if (values) {
        if (this.props.uuid && this.state.edit) {
          values.uuid = this.props.uuid;
        }

        let result = undefined;

        if (this.state.edit) {
          result = await EmployerService.updateDataEmployer(values, urlBase, actions);
        } else {
          result = await EmployerService.addEmployer(values, urlBase, actions);
        }

        let title = '';
        let text = '';
        let icon = '';
        if (result && result === 'OK') {
          title = this.props.t('forms.employer.messages.update.title');
          text = this.props.t('forms.employer.messages.update.success');
          icon = 'success';
        } else {
          title = 'Error';
          text = this.props.t('forms.employer.messages.update.error');
          icon = 'error';
        }

        let okAction = undefined;
        okAction = () => {
          this.props.history.push(employerPaths.EMPLOYER_LIST);
        };
        showDialog(title, text, icon, false, 'OK', false, okAction);
      }
    } catch (error) {
      //console.log('Error', error);
    }
  };

  handleOnback = () => {
    const { employerPaths } = this.props;
    this.props.history.push(employerPaths.EMPLOYER_LIST);
  };

  render() {
    const { roles, titleVariant } = this.props;

    return (
      <EmployerForm
        // providers={this.state.providers}
        // isTrader={this.state.isTrader}
        edit={this.state.edit}
        initialValues={this.state.values}
        onSubmit={this.handleSubmit}
        onback={this.handleOnback}
        roles={roles}
        titleVariant={titleVariant}
      />
    );
  }
}

EmployerContainer.propTypes = {
  t: PropTypes.any,
  roles: PropTypes.array,
  employerPaths: PropTypes.object,
  urlBase: PropTypes.string,
  actions: PropTypes.object,
  titleVariant: PropTypes.string
};

export default withRouter(withTranslation()(EmployerContainer));
