import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as RolesPermissionService from '../service/RolesPermissionService';
import { withTranslation } from 'react-i18next';
import { LinkActionStyled } from './rolesPermissionList.styled';
// import { Can, AbilityContext } from '../../../permissions/Can';
// import { USER_PERMISSIONS } from '../../../permissions/rolesPermission';
import PaginateList from '../../../lists/PaginateList';

class RolesPermissionListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshData: false
    };
  }

  componentDidUpdate(prevState) {
    if (prevState.refreshData !== this.state.refreshData && this.state.refreshData === true) {
      this.resetRefreshData();
    }
  }

  resetRefreshData = () => {
    this.setState({ refreshData: false });
  };

  getHeaderTable = (text) => {
    const { t } = this.props;
    return <span title={t(text)}>{t(text)}</span>;
  };

  getColumns = () => {
    const { t } = this.props;
    return [
      {
        Header: this.getHeaderTable('forms.rolesPermission.table.heads.role'),
        accessor: 'role',
        className: 'text-center',
        Cell: (row) => {
          return row.original.role.toUpperCase();
        }
      },
      {
        Header: this.getHeaderTable('generic.tables.actions'),
        className: 'align-self-center',
        accessor: 'uuid',
        width: 150,
        Cell: (row) => this.renderActions(row)
      }
    ];
  };

  renderActions = (obj) => {
    const { rolesPaths } = this.props;
    return (
      <>
        <div className='text-center'>
          {/* <Can do={USER_PERMISSIONS.ROLES_UPDATE}> */}
          <LinkActionStyled
            to={`${rolesPaths.UPDATE_ROLES}/${obj.original.uuid}`}
            type='routerDom'
            variant='secondary'
          >
            <i className='fas fa-edit' title='Editar' />
          </LinkActionStyled>
          {/* </Can> */}
        </div>
      </>
    );
  };

  toList = async (filter) => {
    const { urlBase, actions } = this.props;
    const result = await RolesPermissionService.listFilter(filter, urlBase, actions);
    return result;
  };

  render() {
    const { t, filters, className, pageSize, titleVariant, iconAddVariant } = this.props;
    return (
      <div className={className || ''}>
        <PaginateList
          title={t('forms.rolesPermission.titleList')}
          defaultPageSize={pageSize}
          columns={this.getColumns()}
          toList={this.toList}
          filters={filters}
          forceRefresh={this.state.refreshData}
          classNameLinkAdd='actionAdd'
          iconAdd='fas fa-plus'
          variantTitle={titleVariant || 'primary'}
          floatButton
          variantIcon={iconAddVariant || 'primary'}
          classNameTable='payper-table'
        ></PaginateList>
      </div>
    );
  }
}

// RolesPermissionListContainer.contextType = AbilityContext;

RolesPermissionListContainer.propTypes = {
  t: PropTypes.any,
  filters: PropTypes.object,
  className: PropTypes.string,
  rolesPaths: PropTypes.object,
  pageSize: PropTypes.number,
  urlBase: PropTypes.string,
  actions: PropTypes.object,
  titleVariant: PropTypes.string,
  iconAddVariant: PropTypes.string
};

export default withTranslation()(RolesPermissionListContainer);
