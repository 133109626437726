import styled, { css } from 'styled-components'

export const ButtonStyled = styled.button`
  ${({ theme, variant }) => {
    return css`
      color: ${theme && theme.buttons ? theme.buttons[variant].color : '#fff'} !important;
      background-color: ${theme && theme.buttons
        ? theme.buttons[variant].backgroundColor
        : '#0d6efd'} !important;
      display: inline-block;
      font-weight: 400;
      line-height: 1.5;
      text-align: center;
      text-decoration: none;
      vertical-align: middle;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      user-select: none;
      border: 1px solid transparent;
      border-color: ${theme && theme.buttons
        ? theme.buttons[variant].borderColor
        : '#0d6efd'} !important;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      border-radius: 0.25rem;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

      &:hover {
        color: ${theme && theme.buttons ? theme.buttons[variant].colorHover : '#fff'} !important;
        background-color: ${theme && theme.buttons
          ? theme.buttons[variant].backgroundColorHover
          : '#0b5ed7'} !important;
        border-color: ${theme && theme.buttons
          ? theme.buttons[variant].borderColorHover
          : '#0a58ca'} !important;
      }

      &:focus {
        color: ${theme && theme.buttons ? theme.buttons[variant].color : '#fff'} !important;
        background-color: ${theme && theme.buttons
          ? theme.buttons[variant].backgroundColor
          : '#0d6efd'} !important;
        border-color: ${theme && theme.buttons
          ? theme.buttons[variant].borderColor
          : '#0d6efd'} !important;
        box-shadow: 0 0 0 0rem rgba(38, 143, 255, 0.5);
        outline: none !important;
      }

      &:active {
        outline: none !important;
      }

      &:disabled:hover {
        color: ${theme && theme.buttons ? theme.buttons[variant].color : '#fff'} !important;
        background-color: ${theme && theme.buttons
          ? theme.buttons[variant].backgroundColor
          : '#0d6efd'} !important;
        border-color: ${theme && theme.buttons
          ? theme.buttons[variant].borderColor
          : '#0d6efd'} !important;
      }
    `
  }}
`
